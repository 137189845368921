<template>
  <div>
    <div class="row">
      <div class="col d-flex justify-content-between">
        <vs-breadcrumb
        :items="
          [
            {
              title: 'Admin Tag Store',
              url: '/'
            },
            {
              title: 'Atributos (Tallas y colores)',
              active: true
            }
          ]"
        ></vs-breadcrumb>
        <div>
          <vs-button icon="add" @click="open = true" type="gradient">Crear nuevo</vs-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <vs-tabs color="success">
          <vs-tab label="Tallas">
            <div class="con-tab-ejemplo">
              <vs-table :data="sizes">
                <template slot="thead">
                  <vs-th>
                    Talla
                  </vs-th>
                  <vs-th>
                    Acciones
                  </vs-th>
                </template>

                <template>
                  <vs-tr class="text-left" :key="indextr" v-for="(tr, indextr) in sizes">
                    <vs-td :data="tr.value">
                      {{tr.value}}
                    </vs-td>

                    <vs-td :data="tr.id">
                      <vs-button icon="create" class="mr-2" @click="editAttribute(tr)" color="primary" type="gradient">Editar</vs-button>
                      <vs-button icon="delete_sweep" @click="deleteAttribute(tr.id, indextr, 'size')" color="danger" type="gradient">Eliminar</vs-button>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vs-tab>
          <vs-tab label="Colores">
            <div class="con-tab-ejemplo">
              <vs-table :data="colors">
                <template slot="thead">
                  <vs-th>
                    Color
                  </vs-th>
                  <vs-th>
                    Código
                  </vs-th>
                  <vs-th>
                    Acciones
                  </vs-th>
                </template>

                <template>
                  <vs-tr class="text-left" :key="indextr" v-for="(tr, indextr) in colors">
                    <vs-td :data="tr.value">
                      {{tr.value}}
                    </vs-td>
                    <vs-td :data="tr.hexadecimal">
                      {{tr.hexadecimal}}
                    </vs-td>

                    <vs-td :data="tr.id">
                      <vs-button icon="create" class="mr-2" @click="editAttribute(tr)" color="primary" type="gradient">Editar</vs-button>
                      <vs-button icon="delete_sweep" @click="deleteAttribute(tr.id, indextr, 'color')" color="danger" type="gradient">Eliminar</vs-button>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>

    <vs-popup classContent="popup-example" :title="edit ? 'Editar atributo' : 'Registrar atributo'" :active.sync="open">

      <div class="row">
        <div class="col">
          <vs-select
            data-vv-as="Tipo del atributo"
            v-validate="'required'"
            name="type"
            autocomplete
            class="selectExample w-100"
            label="Selecciona el tipo del atributo  *"
            v-model="attribute.type"
            color="success"
          >
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in types" />
          </vs-select>
          <span v-show="errors.has('type')" class="w-100 text-danger mt-2">
            {{ errors.first("type") }}
            <br>
          </span>
        </div>
        <div class="col" v-show="attribute.type">
          <label for="">Ingresa {{ attribute.type == 'color' ? 'el nombre del color' : 'la talla' }} *</label>
          <vs-input
            data-vv-as="Valor del atributo"
            v-validate="attribute.type ? 'required' : ''"
            name="value"
            v-model="attribute.value"
            class="inputx w-100"
            placeholder="Valor"
          />
          <span v-show="errors.has('value')" class="w-100 text-danger mt-2">
            {{ errors.first("value") }}
            <br>
          </span>
        </div>
      </div>

      <div class="row mt-2" v-show="attribute.type == 'color'">
        <div class="col">
          <label for="">Ingresa el código hexadecimal del color *</label>
          <vs-input
            v-model="attribute.hexadecimal"
            class="inputx"
            placeholder="Código hexadecimal"
            name="hex"
            data-vv-as="Código hexadecimal"
            v-validate="attribute.type == 'color' ? 'required' : ''"
          />
          <span v-show="errors.has('hex')" class="w-100 text-danger mt-2">
            {{ errors.first("hex") }}
            <br>
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col text-right">
          <vs-button icon="save" :disabled="loading" v-if="edit" class="mt-5" @click="updateAttribute" color="primary" type="gradient">Editar</vs-button>
          <vs-button icon="save" :disabled="loading" v-else class="mt-5" @click="storeAttribute" color="primary" type="gradient">Guardar</vs-button>
        </div>
      </div>

    </vs-popup>

  </div>
</template>

<script>
import ProductService from '@/services/product.service'
export default {
  data () {
    return {
      sizes: [],
      colors: [],
      open: false,
      loading: false,
      edit: false,
      types: [
        {
          text: 'Color',
          value: 'color'
        },
        {
          text: 'Talla',
          value: 'size'
        }
      ],
      attribute: {
        type: '',
        value: '',
        hexadecimal: ''
      }
    }
  },
  watch: {
    open(newVal) {
      if (!newVal) {
        this.edit = false
        this.attribute = JSON.parse(JSON.stringify(this.$options.data().attribute))
      }
    }
  },
  methods: {
    async getAttributes() {
      this.$vs.loading({
        type: 'point'
      })
      const { data:res } = await ProductService.getAttributes()
      res.data.forEach(i => {
        if (i.type == 'color') {
          this.colors.push(i)
        }
        if (i.type == 'size') {
          this.sizes.push(i)
        }
      })
      this.$vs.loading.close()
    },
    async storeAttribute() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          const { data:res } = await ProductService.storeAttribute(this.attribute)

          if (res.success) {
            this.open = false
            if (this.attribute.type == 'color') this.colors.push(res.data)
            if (this.attribute.type == 'size') this.sizes.push(res.data)
            this.$vs.notify({
              title: res.message,
              text:'',
              color: 'success',
              position: 'top-right'
            })
          }
        }
      })
    },
    async updateAttribute() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          const { data:res } = await ProductService.updateAttribute(this.attribute)

          if (res.success) {
            this.open = false
            if (this.attribute.type == 'color') {
              const index = this.colors.findIndex(i => i.id == res.data.id)
              this.colors[index] = res.data
              this.colors = JSON.parse(JSON.stringify(this.colors))
            }
            if (this.attribute.type == 'size') {
              const index = this.sizes.findIndex(i => i.id == res.data.id)
              this.sizes[index] = res.data
              this.sizes = JSON.parse(JSON.stringify(this.sizes))
            }
            this.$vs.notify({
              title: res.message,
              text:'',
              color: 'success',
              position: 'top-right'
            })
          }
        }
      })
    },
    deleteAttribute(id, index, type) {
      this.$confirm(
        {
          message: `Seguro de eliminar el atributo?`,
          button: {
            no: 'No',
            yes: 'Si'
          },
          callback: async confirm => {
            if (confirm) {
              const { data:res } = await ProductService.deleteAttribute({
                id: id
              })
              if (res.success) {
                if (type == 'color') this.colors.splice(index, 1)
                if (type == 'size') this.sizes.splice(index, 1)
              } else {
                this.$swal(res.message, '', 'warning')
              }
            }
          }
        }
      )
    },
    editAttribute(item) {
      this.attribute = JSON.parse(JSON.stringify(item))
      this.edit = true
      this.open = true
    }
  },
  created() {
    this.getAttributes()
  }
}
</script>
